<template>
  <auth-layout>
    <div class="auth-contents">
      <a-card :bordered="false" layout="vertical">
        <sd-heading as="h3">
            {{$t('views.login.signInAs')}}
            <span class="color-secondary">
              {{$t(`views.login.${$_.camelCase(currentRole)}`)}}
            </span>
        </sd-heading>
        <app-form
          ref="loginForm"
          :fields="fields"
          :hideRequiredMark="true"
          :loading="loading"
          :submitButton="submitButton"
          :hasAlertList="true"
          @submitted="handleSubmit"
        >
          <template v-slot:alertList>
            <sd-alerts v-if="!$_.isEmpty(error)" message="" :description="error" :closable="false" type="error"/>
          </template>
          <template v-slot:actions>
            <div class="auth-form-action">
              <a-checkbox v-if="false">{{$t('views.login.keepMeLoggedIn')}}</a-checkbox>
              <router-link
                v-if="currentRole" class="forgot-pass-link"
                :to="{name: forgotPasswordLink}">
                {{$t('views.login.forgotPassword')}}
              </router-link>
            </div>
            <router-link v-if="['Agent', 'Client'].includes(currentRole)"
              :to="`/${currentRole.toLowerCase()}/auth/reapply/`">
              {{$t('views.login.reApplyHere')}}
            </router-link>
          </template>
        </app-form>
        <template v-if="currentRole === 'BackOffice'">
          <p class="auth-notice mt-30">
            {{$t('views.login.wantToSignInAs') + ' ' + $t('views.login.agent')}}?
            <router-link to="/agent/auth/">
              {{$t('actions.clickHere')}}
            </router-link>
          </p>
          <p class="auth-notice">
            {{$t('views.login.wantToSignInAs') + ' ' + $t('views.login.client')}}?
            <router-link to="/client/auth/">
              {{$t('actions.clickHere')}}
            </router-link>
          </p>
        </template>
        <template v-else>
          <p class="auth-notice mt-30">
            {{$t('views.login.wantToSignInAs') + ' ' + alternativeRole.toLowerCase()}}?
            <router-link :to="`/${alternativeRole.toLowerCase()}/auth/`">
              {{$t('actions.clickHere')}}
            </router-link>
          </p>
          <p class="auth-notice">
            {{$t('views.login.backOffice')}}?
            <router-link :to="`/back-office/auth/`">
              {{$t('actions.clickHere')}}
            </router-link>
          </p>
        </template>
        <p class="auth-notice" v-if="currentRole === 'Client' || currentRole === 'Agent'">
          {{$t('views.login.noAccount')}}
          <router-link :to="`/${currentRole.toLowerCase()}/registration/`">
            {{$t('views.login.registerHere')}}
          </router-link>
          <template v-if="currentRole === 'Agent'">
            {{$t('views.login.or')}}
            <router-link :to="{name: 'AgentContinueRegistration'}">
              {{$t('views.registration.continueRegistration')}}
            </router-link>
          </template>
        </p>
      </a-card>
    </div>
  </auth-layout>
</template>
<script>
import {
  computed, watch, ref, reactive
} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {AuthLayout} from './style';

export default {
  name: 'Login',
  components: {
    AuthLayout
  },
  setup() {
    const {
      state, commit, dispatch
    } = useStore();
    const route = useRoute();

    const loginForm = ref(null);
    const error = computed(() => state.layout.error);

    const currentRole = ref('');
    const alternativeRole = ref('');

    const setRoles = () => {
      currentRole.value = route.meta.key;
      alternativeRole.value = currentRole.value === 'Agent' ? 'Client' : 'Agent';
      commit('setUserType', route.meta.userType);
      commit('setError', '');
      loginForm?.value?.cancelSubmit();
    };

    watch(() => route.path, () => {
      if (route.name === 'BackOfficeLogin' || route.name === 'AgentLogin' || route.name === 'ClientLogin') {
        setRoles();
      }
    }, {immediate: true});

    const fields = {
      email: {
        type: 'text',
        class: 'mb-15',
        size: 'large',
        label: 'models.user.attributes.email',
        fieldValue: '',
        span: 24
      },
      password: {
        type: 'password',
        class: 'mb-15',
        label: 'models.user.attributes.password',
        fieldValue: '',
        size: 'large',
        span: 24
      }
    };
    const submitButton = reactive({
      label: 'actions.signIn',
      class: 'btn-signin',
      size: 'large',
      type: 'primary',
      block: false
    });
    const loading = ref(false);
    const handleSubmit = (data) => {
      loading.value= true;
      dispatch('login', data).then(() => loading.value = false);
    };

    const forgotPasswordLink = computed(() => {
      switch (currentRole.value) {
      case 'Agent':
        return 'AgentForgotPassword';
      case 'Client':
        return 'ClientForgotPassword';
      case 'BackOffice':
        return 'BackOfficeForgotPassword';
      default:
        return undefined;
      }
    });

    return {
      loginForm,
      currentRole,
      alternativeRole,
      fields,
      submitButton,
      loading,
      error,
      handleSubmit,
      forgotPasswordLink
    };
  }
};
</script>
